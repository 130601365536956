import { getCookie } from 'Utils';

const isProd = process.env.NODE_ENV === 'production' && !process.env.staging;

export const GA_TRACKING_ID = 'UA-141355038-1';
const G4_TRACKING_ID = 'G-JKYF5XQR92';
const AW_TRACKING_ID = 'AW-708480371';
const AW_CONVERSION_LABEL = 'xLWmCPSfkrQBEPOa6tEC';

// Sale of data cookie - do not track any analytics if it is present.
const hasSod = () => typeof document !== 'undefined' && +getCookie('sod') === 1;

// Sec-GPC header - ALSO do not track any analytics if this function returns true
// More info: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
const hasGPCHeader = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const gpcPreference = navigator.globalPrivacyControl;

  return gpcPreference === '1' || gpcPreference === 1 || gpcPreference === true; // browsers implement differently
};

const saleOfDataProhibited = hasSod() || hasGPCHeader();

export const BEACON_NAMES = {
  SELECTION: 'inhomeDeviceInstallSelection',
  SCHEDULE: 'inhomeScheduleInstallation',
  REVIEW: 'inhomeReviewInstallationAppointment',
  CONFIRMATION: 'inhomeInstallationAppointmentConfirmation'
};

export const beaconCall = (action, name, payload) => {
  if (typeof window !== 'undefined') {
    if (window._bcq) {
      window._bcq.push([
        '_tagAction',
        'inhome', // context
        action, // action
        name, // name
        payload, // payload
      ]);
    }
  }
};

export const pixelInit = () => {
  if (typeof window !== 'undefined' && !saleOfDataProhibited) {
    // will not initialize tracking if sod present
    if (window.gtag) {
      window.gtag('js', new Date());
      window.gtag('config', GA_TRACKING_ID, {
        page_path: `${window.location.pathname}${window.location.search}`,
        custom_map: {
          dimension1: 'clientId',
          dimension2: 'userId',
          dimension3: 'partnership',
        },
      });
      window.gtag('config', AW_TRACKING_ID);
      window.gtag('config', G4_TRACKING_ID);
    }

    if (isProd) {
      if (window.fbq) {
        window.fbq('init', '1268877009975741');
        window.fbq('track', 'PageView');
      }

      if (window.twq) {
        window.twq('init', 'o3j7b');
        window.twq('track', 'PageView');
      }
    }
  }
};

/**
 * Page view util function
 */

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const gaPageView = (url) => {
  if (url) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
      custom_map: {
        dimension1: 'clientId',
        dimension2: 'userId',
        dimension3: 'partnership',
      },
    });
  }
};

const fbPageView = () => {
  if (window.fbq) {
    window.fbq('track', 'PageView');
  }
};

const twitterPageView = () => {
  if (window.twq) {
    window.twq('track', 'PageView');
  }
};

export const pageView = (url) => {
  if (url && !saleOfDataProhibited) {
    // if no sod cookie present send page view
    gaPageView(url);
    isProd && fbPageView() + twitterPageView();
  }
};

/**
 * Log event util function
 */

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gaLogEvent = (name, data) => {
  if (!saleOfDataProhibited) {
    // if no sod cookie present send event
    window.gtag('event', data, {
      event_category: name,
    });
  }
};

export const fbLogEvent = (name, data) => {
  if (isProd && window.fbq && name && data && !saleOfDataProhibited) {
    // if no sod cookie present send fb event
    window.fbq('trackCustom', name, { content_name: data });
  }
};

export const googleEcommEvent = (name, data) => {
  !saleOfDataProhibited && window.gtag('event', name, data); // if no sod cookie present send e-comm event
};

export const fbConversionEvent = (event, options) => {
  isProd && !saleOfDataProhibited && window.fbq('track', event, options); // if no sod cookie present send fb conversion event
};

export const eCommEvent = (data) => {
  if (data && !saleOfDataProhibited) {
    // if no sod cookie present send purchase event
    window.gtag('event', 'purchase', data);
    window.gtag('event', 'conversion', {
      send_to: `${AW_TRACKING_ID}/${AW_CONVERSION_LABEL}`,
      transaction_id: data.transaction_id,
      value: data.value,
      currency: data.currency,
    });
  }
};

export const logEvent = (name, data) => {
  if (!saleOfDataProhibited) {
    // if no sod cookie present send event
    gaLogEvent(name, data);
    isProd && fbLogEvent(name, data);
  }
};

export const pandoraEvent = (action) => {
  if (!saleOfDataProhibited) {
    const pixel = document.getElementById('Pandora');
    let img = document.createElement('img');
    img.id = 'Pandora';
    img.src = `https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=${action}&pixid=a752492d-f8cc-4111-ad0e-9e030b7627e9`;
    img.width = 1;
    img.height = 1;
    img.border = 0;

    if (pixel) {
      pixel.remove();
    }

    document?.body?.appendChild(img);
  }
};

export const jungroupEvent = (event) => {
  let ordnumber = Math.random() * 10000000000000;
  let gid;
  switch (event) {
    case 'get_started':
      gid = 33356;
      break;
    case 'get_inhome_place_order':
      gid = 33357;
      break;
    default:
      gid = 33355;
      break;
  }
  let sscUrl = ('https:' == document.location.protocol ? 'https://' : 'http://') + `trkn.us/pixel/conv/ppt=11560;g=${event};gid=${gid};ord=` + ordnumber + ';v=120';
  let x = document?.createElement('IMG');

  x.setAttribute('src', sscUrl);
  x.setAttribute('width', '1');
  x.setAttribute('height', '1');
  document?.body?.appendChild(x);
};

export const zemantaEvent = (event = '') => {
  if (!saleOfDataProhibited) {
    const pixel = document.getElementById('Zemanta');
    let img = document.createElement('img');
    img.id = 'Zemanta';
    img.src = `https://p1.zemanta.com/p/15318/${event === 'conversion' ? 10199 : 10200}/`;
    img.width = 1;
    img.height = 1;
    img.border = 0;

    if (pixel) {
      pixel.remove();
    }

    document?.body?.appendChild(img);
  }
};

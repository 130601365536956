import React, { useState, useEffect, useRef, useContext } from 'react';
import NextLink from 'next/link';
import styled from '@emotion/styled';
import queryString from 'query-string';

import { DefaultContext } from 'Context/DefaultContext';

import colors from 'Utils/theme';
import { logEvent, googleEcommEvent, fbConversionEvent } from 'Utils/analytics';
import { getCookie } from 'Utils';
import { responsiveFont } from 'Styles/helpers';

import { Button as LDButton } from '@walmart-web/livingdesign-components';

const A = styled.a`
  ${responsiveFont({ sizes: '16,18' })};

  font-weight: 400;

  color: ${(props) => (props.color ? props.color : colors.walmart.blue)};
  cursor: ${(props) => (props.inactive ? 'default' : 'pointer')};
`;

const Link = ({ title, children, href, as = href, className, scroll = false, targetBlank = null, track = false, inactive, trackId, clickFunction = null, color, asLDButton = false, ...props }) => {
  const { isMobile, defaultOfferId } = useContext(DefaultContext);

  const [wPlusParams, setWPlusParams] = useState('');

  const isExternal = href && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  // if targetBlank is null and it is an external link, we want it to default to true
  if (isExternal && targetBlank === null) {
    targetBlank = true;
  }

  const hrefHostname = isExternal && new URL(href).hostname.replace('www.', '');
  const isWalmartURL = hrefHostname && hrefHostname.includes('walmart.com') && !hrefHostname.includes('inhome');
  const wPlusCookie = getCookie('plus_params');

  useEffect(() => {
    if (isWalmartURL && wPlusCookie) {
      // if the href already has query params, append the plus_params with a & instead of a ?
      const leadingChar = href.includes('?') ? '&' : '?';
      setWPlusParams(`${leadingChar}${queryString.stringify(JSON.parse(wPlusCookie))}`);
    }
  }, [isWalmartURL, wPlusCookie]);

  const ref = useRef(null);

  useEffect(() => {
    if (props.autoFocus && ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleClick = () => {
    if (track) {
      const page = trackId || `"${String(children)}" clicked`;

      if (page === 'add_to_cart' && defaultOfferId) {
        return (
          googleEcommEvent(page, { items: [{ id: defaultOfferId, name: 'InHome smart device' }] }) +
          fbConversionEvent('AddToCart', {
            content_name: 'InHome smart device',
            content_ids: [defaultOfferId],
            content_type: 'product',
            value: 49.95,
            currency: 'USD',
          })
        );
      }

      logEvent('Navigation', page);
    }
  };

  const getStringFromChildren = (children) => {
    switch (typeof children) {
      case 'string':
        return children;
      case 'object':
        if (Array.isArray(children)) {
          return children.filter((child) => child && typeof child === 'string').join(' ');
        }
    }
  };

  if (isExternal && children) {
    return asLDButton ? (
      <LDButton href={`${href}${wPlusParams}`} {...props} target={targetBlank ? '_blank' : undefined} rel="noopener noreferrer" title={title ? title : { children }} ref={ref} onClick={handleClick}>
        {children}
      </LDButton>
    ) : (
      <A color={color} href={`${href}${wPlusParams}`} {...props} target={targetBlank ? '_blank' : undefined} rel="noopener noreferrer" className={className} title={title ? title : { children }} ref={ref} onClick={handleClick}>
        {children}
      </A>
    );
  }

  if (inactive) {
    return (
      <div {...props} className={className} ref={ref}>
        {children}
      </div>
    );
  }

  if (children) {
    return (
      <NextLink href={href} as={as} scroll={isMobile || scroll}>
        {asLDButton ? (
          <LDButton
            target={targetBlank ? '_blank' : undefined}
            rel="noopener noreferrer"
            href={href ? (href.pathname ? href.pathname : href) : '/'}
            title={getStringFromChildren(typeof children === 'object' ? (children.props && children.props.children ? children.props.children : '') : children)}
            {...props}
            ref={ref}
            onClick={() => handleClick() + clickFunction && clickFunction()}
          >
            {children}
          </LDButton>
        ) : (
          <A
            color={color}
            target={targetBlank ? '_blank' : undefined}
            rel="noopener noreferrer"
            href={href ? (href.pathname ? href.pathname : href) : '/'}
            className={className}
            title={getStringFromChildren(typeof children === 'object' ? (children.props && children.props.children ? children.props.children : '') : children)}
            {...props}
            ref={ref}
            onClick={() => handleClick() + clickFunction && clickFunction()}
          >
            {children}
          </A>
        )}
      </NextLink>
    );
  } else {
    return null;
  }
};

Link.displayName = 'Link';

export default Link;
